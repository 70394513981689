<template>
  <div class="burger-menu">
    <main-button
      v-show="!isMd"
      tag="button"
      data-test-id="home-burger-menu"
      class-variant="btn-icon icon-only"
      title="burger-menu"
      @click="toggleMenu"
    >
      <div class="icon-menu u-bg-dark-blue" />
    </main-button>
    <div
      :class="{ open: visible, hidden: !visible }"
      class="burger-menu-wrapper"
      data-test-id="home-burger-menu-wrapper"
    >
      <div class="burger-menu-bg" @click="visible = false" />
      <div class="burger-menu-content" data-test-id="home-burger-menu-content">
        <div class="burger-menu-cols" :data-level="selectedLevel">
          <!-- MAIN MENU -->
          <div
            v-if="!showCategories && !showInspirations"
            data-test-id="home-burger-menu-col-0"
            class="burger-menu-col custom-scrollbar custom-scrollbar-blue"
          >
            <div class="burger-menu-heading">
              <div class="burger-menu-heading-content" data-test-id="home-burger-menu-heading-content">
                <div
                  class="burger-menu-close icon-close u-bg-dark-blue burger-close"
                  data-test-id="home-burger-menu-close"
                  @click="visible = false"
                />
                <div
                  class="burger-menu-title-categories-icon icon-arrow-left u-bg-dark-blue show-sm"
                  data-test-id="home-burger-menu-title-inspirations"
                />
                <NuxtLinkLocale :to="'index'" class="logo-container" data-test-id="home-burger-menu-logo">
                  <img
                    src="@/assets/img/svg/logos/logo-stockpro-color.svg"
                    :alt="$t('header.logoAlt')"
                    :title="$t('header.logoAlt')"
                    class="logo"
                    data-test-id="home-burger-menu-logo-img"
                  />
                </NuxtLinkLocale>
              </div>
            </div>
            <client-only>
              <div class="burger-menu-list" data-test-id="home-burger-menu-list">
                <!-- Special categories -->
                <div class="burger-menu-list-1" data-test-id="home-burger-menu-list-1">
                  <div class="burger-menu-item" data-test-id="burger-menu-see-category" @click="toggleCategories">
                    <a class="burger-menu-item-link" data-test-id="home-burger-menu-item-see-category">
                      <p data-test-id="home-burger-menu-item-see-category-text">
                        {{ $t('actions.seeCategories') }}
                      </p>
                    </a>
                  </div>
                  <div
                    v-for="category in specialCategories"
                    :key="category.label"
                    :data-test-id="'burger-menu-' + category.testId"
                    class="burger-menu-item"
                    @click="selectCategory(null, 0)"
                  >
                    <NuxtLinkLocale :to="category.link" class="burger-menu-item-link" @click="toggleMenu">
                      <p>{{ category.label }}</p>
                    </NuxtLinkLocale>
                  </div>
                </div>

                <!-- Brands to Mission -->
                <div class="burger-menu-list-2" data-test-id="home-burger-menu-list-2">
                  <div
                    class="burger-menu-item"
                    data-test-id="home-burger-menu-item-brands-mission"
                    @click="selectCategory(null, 0)"
                  >
                    <NuxtLinkLocale
                      :to="'brands'"
                      class="burger-menu-item-link"
                      data-test-id="home-burger-menu-item-brands"
                      @click="toggleMenu"
                    >
                      <p data-test-id="home-burger-menu-item-brands-text">
                        {{ $t('pages.brands') }}
                      </p>
                    </NuxtLinkLocale>
                  </div>
                  <div v-if="featuredInspirations?.length > 0" class="burger-menu-item" @click="toggleInspirations">
                    <a class="burger-menu-item-link" data-test-id="home-burger-menu-item-rooms">
                      <p>{{ $t('actions.housesRooms') }}</p>
                    </a>
                  </div>
                  <div v-if="seasonalSelectionMenu" class="burger-menu-item" @click="selectCategory(null, 0)">
                    <a
                      :href="seasonalSelectionMenu.url"
                      class="burger-menu-item-link"
                      data-test-id="home-burger-menu-item-seasonal-selection"
                      @click="toggleMenu"
                    >
                      <p>{{ seasonalSelectionMenu.name }}</p>
                    </a>
                  </div>
                  <div class="burger-menu-item" @click="selectCategory(null, 0)">
                    <a
                      :href="getProUrl()"
                      class="burger-menu-item-link"
                      data-test-id="home-burger-menu-item-pro"
                      @click="toggleMenu"
                    >
                      <p>{{ $t('pages.ourProSolutions') }}</p>
                    </a>
                  </div>
                  <div class="burger-menu-item" @click="selectCategory(null, 0)">
                    <NuxtLinkLocale
                      :to="'mission'"
                      class="burger-menu-item-link"
                      data-test-id="home-burger-menu-item-mission"
                      @click="toggleMenu"
                    >
                      <p>{{ $t('pages.ourMission') }}</p>
                    </NuxtLinkLocale>
                  </div>
                </div>

                <!-- Vouchers to FAQ -->
                <div class="burger-menu-list-3" data-test-id="home-burger-menu-list-3">
                  <div v-if="countryCodeFR" class="burger-menu-item" @click="selectCategory(null, 0)">
                    <NuxtLinkLocale
                      to="/category/cheque-cadeau--80cf"
                      class="burger-menu-item-link"
                      data-test-id="home-burger-menu-item-vouchers"
                      @click="toggleMenu"
                    >
                      <p>{{ $t('pages.vouchers') }}</p>
                    </NuxtLinkLocale>
                  </div>
                  <client-only>
                    <div class="burger-menu-item" @click="selectCategory(null, 0)">
                      <a
                        v-if="contactFormLink"
                        :href="contactFormLink"
                        target="_blank"
                        class="burger-menu-item-link"
                        data-test-id="home-burger-menu-item-contact-us"
                        @click="toggleMenu"
                      >
                        <p>{{ $t('actions.contactUs') }}</p>
                      </a>
                    </div>
                    <div class="burger-menu-item" @click="selectCategory(null, 0)">
                      <a
                        v-if="faqLink"
                        :href="faqLink"
                        target="_blank"
                        class="burger-menu-item-link"
                        data-test-id="home-burger-menu-item-faq"
                        @click="toggleMenu"
                      >
                        <p>{{ $t('pages.faq') }}</p>
                      </a>
                    </div>
                  </client-only>
                </div>

                <!-- Signup/Signin -->
                <div v-if="!user?.isAuthenticated" class="burger-menu-login" data-test-id="home-burger-menu-login">
                  <div class="u-p-b-sm" data-test-id="home-burger-menu-login-title">
                    <p class="u-c-steel-grey">{{ $t('actions.logInExp') }}</p>
                  </div>
                  <div class="u-p-b-sm">
                    <main-button
                      :link="localePath('signup')"
                      :label="$t('actions.register')"
                      data-test-id="home-burger-menu-login-register"
                      class-variant="btn-dark-blue-white btn-hover-primary"
                      @click="toggleMenu"
                    />
                  </div>
                  <div>
                    <main-button
                      :link="localePath('login')"
                      :label="$t('actions.login')"
                      data-test-id="home-burger-menu-login-login"
                      class-variant="btn-grey btn-hover-light-grey"
                      @click="toggleMenu"
                    />
                  </div>
                </div>
              </div>
            </client-only>
          </div>
          <!-- END MAIN MENU -->
          <!-- MAIN CATS MENU -->
          <div
            v-show="showCategories"
            data-test-id="home-burger-menu-col-categories"
            class="burger-menu-col custom-scrollbar custom-scrollbar-blue"
          >
            <div class="burger-menu-heading">
              <div class="burger-menu-heading-content" data-test-id="home-burger-menu-heading-categories">
                <div
                  class="burger-menu-close icon-close u-bg-dark-blue burger-close"
                  data-test-id="home-burger-menu-close-categories"
                  @click="visible = false"
                />
                <!-- Arrow left return from category -->
                <div
                  class="burger-menu-title-categories-icon icon-arrow-left u-bg-dark-blue show-sm"
                  data-test-id="home-burger-menu-title-categories"
                  @click="toggleCategories"
                />
              </div>
              <div class="burger-menu-title-categories" :data-test-id="`home-burger-menu-title-categories-category`">
                <div
                  class="burger-menu-title-categories-icon icon-arrow-left u-bg-dark-blue hide-sm"
                  data-test-id="home-burger-menu-product-category"
                  @click="toggleCategories"
                />
                <p>{{ $t('product.category') }}</p>
              </div>
            </div>
            <client-only>
              <div class="burger-menu-list" data-test-id="home-burger-menu-list-categories">
                <div class="burger-menu-item-category" data-test-id="home-burger-menu-item-category">
                  <div
                    v-for="(category, i) in menuCategories"
                    v-show="showCategories"
                    :key="category.uid"
                    :event="'click'"
                    class="burger-menu-item-link"
                    :data-test-id="`home-burger-menu-item-category-${i}`"
                    :class="selectedCategory?.uid === category?.uid ? 'active' : ''"
                    @click="selectCategory(category, 1)"
                  >
                    <img
                      :src="category.image_on"
                      :alt="category.name"
                      :title="category.name"
                      class="burger-menu-item-link-on"
                    />
                    <img
                      :src="category?.image_off || category?.thumbnail"
                      :alt="category.name"
                      :title="category.name"
                      class="burger-menu-item-link-off"
                    />
                    {{ category.name }}
                    <div class="burger-menu-item-link-icon icon-angle-right u-bg-dark-blue" />
                  </div>
                </div>
              </div>
            </client-only>
          </div>
          <!-- END MAIN CATS MENU -->
          <!-- SUB CATS MENUS -->
          <div
            v-for="(el, i) in menuCategories"
            v-show="showCategories && selectedCategory?.uid === el?.uid"
            :key="i"
            :data-test-id="`home-burger-menu-col-category-${i}-children`"
            class="burger-menu-col custom-scrollbar custom-scrollbar-blue"
          >
            <client-only>
              <div class="burger-menu-heading">
                <p class="burger-menu-title" :data-test-id="`home-burger-menu-title-category-${i}-children`">
                  {{ el.name }}
                </p>
              </div>
              <div class="burger-menu-list" :data-test-id="`home-burger-menu-list-category-${i}-children`">
                <div class="burger-menu-item u-p-l-xl">
                  <NuxtLinkLocale
                    :to="{ name: 'category-slug', params: { slug: el.slug } }"
                    class="burger-menu-item-link all-products"
                    :data-test-id="`home-burger-menu-item-all-products-${i}`"
                    @click="toggleMenu"
                  >
                    {{ $t('actions.allProducts') }}
                  </NuxtLinkLocale>
                </div>
                <div
                  class="burger-menu-item-category subcategory"
                  :data-test-id="`home-burger-menu-item-category-${i}-children`"
                >
                  <div
                    v-for="(category, j) in el.children"
                    :key="category.uid"
                    class="burger-menu-item-link"
                    :data-test-id="`home-burger-menu-item-category-${i}-children-${j}`"
                    @click="openCategory(category)"
                  >
                    {{ category.name }}
                  </div>
                </div>
              </div>
            </client-only>
          </div>
          <!-- END SUB CATS MENUS -->
          <!-- INSPIRATION MENU -->
          <div
            v-if="showInspirations && featuredInspirations.length > 0"
            data-test-id="home-burger-menu-col-inspiration"
            class="burger-menu-col custom-scrollbar custom-scrollbar-blue"
          >
            <div class="burger-menu-heading">
              <div class="burger-menu-heading-content" data-test-id="home-burger-menu-headind-inspirations-content">
                <div class="burger-menu-title-inspirations" data-test-id="home-burger-menu-title-inspiration">
                  <div
                    class="burger-menu-title-categories-icon icon-arrow-left u-bg-dark-blue hide-sm"
                    data-test-id="home-burger-menu-actons-rooms"
                    @click="toggleInspirations"
                  />
                  <p>{{ $t('actions.rooms') }}</p>
                </div>
              </div>
              <client-only>
                <div class="burger-menu-list" data-test-id="home-burger-menu-list-inspiration">
                  <div class="burger-menu-item-inspiration" data-test-id="home-burger-menu-item-inspiration">
                    <div
                      v-for="(inspiration, idx) in featuredInspirations"
                      :key="idx"
                      class="burger-menu-item-inspiration-item"
                      :data-test-id="`home-burger-menu-item-inspiration-${idx}`"
                      @click="selectInspiration(inspiration.url)"
                    >
                      <div
                        class="burger-menu-item-inspiration-item-img"
                        :data-test-id="`home-burger-menu-item-inspiration-${idx}-img`"
                      >
                        <ImageLazy
                          v-if="inspiration.image?.filename"
                          :src="inspiration.image?.filename"
                          :alt="inspiration.name"
                          width="135"
                          height="90"
                          :background-image="true"
                        />
                      </div>
                      <p
                        class="burger-menu-item-inspiration-item-name"
                        :data-test-id="`home-burger-menu-item-inspiration-${idx}-name`"
                      >
                        {{ inspiration.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </client-only>
            </div>
          </div>
          <!-- END INSPIRATION MENU -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/mixins/sp-api-mixin'
import URL from '@/mixins/url-mixin'
import i18nMixin from '@/mixins/i18n-mixin'
import dynamicContentMx from '@/mixins/dynamic-content-mixin'
import { useDynamicContentStore } from '@/stores/dynamicContent'
import { nextTick } from 'vue'

import MainButton from '@/components/Buttons/MainButton/MainButton'
import { useDeviceSize } from '@/composables/useDeviceSize'

export default {
  name: 'BurgerMenu',
  components: {
    MainButton,
  },
  mixins: [API, URL, i18nMixin, dynamicContentMx],
  data() {
    return {
      visible: false,
      selectedCategory: null,
      selectedLevel: 0,
      showCategories: false,
      showInspirations: false,
      proUrl: '',
      tagsStocks: [],
      menuCategories: [],
    }
  },
  computed: {
    isMd() {
      return useDeviceSize().isMd.value
    },
    isSm() {
      return useDeviceSize().isSm.value
    },
    featuredInspirations() {
      const dynamicContentStore = useDynamicContentStore()
      return dynamicContentStore.getFeaturedInspirations?.filter(inspiration => {
        return !!this.tagsStocks.find(x => x?.tag === inspiration?.url?.split('?tags=')?.[1] && x?.stock_quantity > 10)
      })
    },
    hasGreenEveryday() {
      return this.tagsStocks?.find(x => x.tag === 'ged')?.stock_quantity > 10
    },
    user() {
      const { $currentUser } = useNuxtApp()
      return $currentUser()
    },
    specialCategories() {
      const localePath = useLocalePath()

      const list = [
        {
          label: this.$t('actions.newProducts'),
          link: localePath('category-new-products'),
          testId: 'category-new-products',
        },
        {
          label: this.$t('actions.bestDeals'),
          link: localePath('category-best-deals'),
          testId: 'category-best-deals',
        },
        {
          label: this.$t('actions.bestSellers'),
          link: localePath('category-best-sellers'),
          testId: 'category-best-sellers',
        },
        // {
        //   label: this.$t('actions.specialDiscounts'),
        //   link: localePath('category-special-discounts'),
        // },
      ]
      if (this.hasGreenEveryday) {
        list.push({
          label: this.$t('actions.greenEveryday'),
          link: localePath('category-green-everyday'),
        })
      }
      return list
    },
    seasonalSelectionMenu() {
      const dynamicContentStore = useDynamicContentStore()
      return dynamicContentStore.getFeaturedSeasonalSelectionsMenu
    },
    contactFormLink() {
      const { country, locale } = useCurrentLocale()
      return country?.contactFormLink[locale]
    },
    faqLink() {
      const { country, locale } = useCurrentLocale()
      return country?.faqLink[locale]
    },
    countryCodeFR() {
      const { country } = useCurrentLocale()
      return country?.code === 'fr'
    },
  },
  beforeMount() {
    const { events } = useAnalytics()
    this.$bus.on(events.OPEN_BURGER_MENU_CATEGORIES, () => {
      this.toggleMenu()

      nextTick(() => {
        this.visible = true
        this.showCategories = true
      })
    })
    this.$bus.on(events.CLOSE_BURGER_MENU, () => (this.visible = false))
  },
  beforeUnmount() {
    const { events } = useAnalytics()
    this.$bus.off(events.OPEN_BURGER_MENU_CATEGORIES)
    this.$bus.off(events.CLOSE_BURGER_MENU)
  },
  mounted() {
    this.getDynamicContent({
      contents: [
        // 'featuredinspirations',
        'featuredseasonalselections',
      ],
    })
    this.proUrl = this.getProUrl()
    const { $api } = useNuxtApp()
    this.spRequest({ req: $api().category.getBurgerMenu() }).then(results => {
      this.menuCategories = results
    })
    this.spRequest({ req: $api().tags.getStocksTags() }).then(results => {
      this.tagsStocks = results
    })
  },
  methods: {
    toggleCategories() {
      if (this.showCategories && this.selectedLevel > 0) {
        this.selectedLevel = 0
        this.selectedCategory = null
      } else {
        this.showCategories = !this.showCategories
      }
    },
    toggleInspirations() {
      this.showInspirations = !this.showInspirations
    },
    openCategory(category) {
      const localePath = useLocalePath()
      this.$router.push(localePath({ name: 'category-slug', params: { slug: category.slug } }))
      this.toggleMenu()
    },
    toggleMenu() {
      this.visible = !this.visible
      this.selectedCategory = null
      this.selectedLevel = 0
      this.showCategories = false
      this.showInspirations = false
    },
    selectCategory(category, level) {
      this.selectedLevel = level
      this.selectedCategory = category
    },
    selectInspiration(url) {
      const localePath = useLocalePath()
      this.toggleMenu()
      this.$router.push(localePath(url))
    },
  },
}
</script>

<style lang="scss" scoped>
.burger-menu {
  position: fixed;
  left: $spacing-md;
  top: $spacing-md;
  background-color: var(--white);
  z-index: 13;

  @include mqmin($content-max-width) {
    left: calc(((100vw - $content-max-width) / 2) + 13px);
  }

  @include mq($mq-sm) {
    position: relative;
    left: $spacing-sm;
    top: $spacing-sm;
  }

  .burger-menu-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    visibility: hidden;
    transition: visibility var(--transition-duration) var(--transition-timing-function);

    .burger-menu-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--black);
      opacity: 0;
      z-index: 1;
      transition: opacity var(--transition-duration) var(--transition-timing-function);
    }

    .burger-menu-content {
      position: relative;
      z-index: 2;
      background-color: var(--white);
      height: 100vh;
      display: inline-flex;
      transform: translate(-100%, 0);
      transition: transform var(--transition-duration) var(--transition-timing-function);

      .burger-menu-cols {
        display: flex;
        transition: transform var(--transition-duration) var(--transition-timing-function);

        @include mq($mq-sm) {
          @for $level from 0 through 3 {
            &[data-level='#{$level}'] {
              transform: translate(calc(#{$level} * -100vw), 0);
            }
          }
        }

        .burger-menu-col {
          padding-top: 24px;
          border-right: 2px solid var(--background-blue);
          width: 330px;
          height: 100%;
          display: flex;
          flex-direction: column;

          @include mq($mq-sm) {
            width: 100vw;
          }

          &:last-child {
            border: 0;
          }

          .burger-menu-heading {
            flex-shrink: 1;

            &-content {
              display: flex;
              align-items: center;
              margin-bottom: $spacing-xl;

              @include mq($mq-sm) {
                justify-content: space-between;
                padding: 0 $spacing-md;
                margin-bottom: $spacing-xl;
              }

              .logo-container {
                margin-left: 100px !important;
                display: flex;

                @include mq($mq-sm) {
                  margin-left: 0 !important;
                }

                .logo {
                  max-width: 151px !important;
                  max-height: 34px !important;
                  top: 0 !important;
                }
              }

              .burger-menu-close {
                position: absolute;
                left: $spacing-md;
                cursor: pointer;

                @include mq($mq-sm) {
                  position: relative;
                  order: 3;
                  left: inherit;
                }
              }
            }

            .burger-menu-title {
              padding-top: 80px;
              padding-right: $spacing-md;
              margin-bottom: $spacing-xl;
              padding-left: $spacing-xl;
              font-size: pxToRem(25px);
              font-weight: 800;
              @include line-clamp(2);

              @include mq($mq-sm) {
                padding-top: 0;
              }

              &-categories,
              &-inspirations {
                display: flex;
                align-items: center;
                padding-left: $spacing-md;
                padding-bottom: $spacing-xl;

                @include mq($mq-sm) {
                  padding-left: $spacing-xl;
                }

                &-icon {
                  cursor: pointer;
                }

                p {
                  font-size: pxToRem(25px) !important;
                  font-weight: 800;
                  padding-left: $spacing-xxl;

                  @include mq($mq-sm) {
                    padding-left: 0;
                  }
                }
              }

              &-inspirations {
                @include mq($mq-sm) {
                  padding-left: $spacing-md;
                  padding-bottom: $spacing-md;

                  p {
                    font-size: pxToRem(20px) !important;
                  }
                }
              }
            }

            .btn {
              margin: 15px 0 0 0;
            }
          }

          .burger-menu-list {
            padding-bottom: $spacing-md;
            flex-grow: 1;
            overflow: auto;

            @extend .burger-menu-custom-scrollbar;

            .burger-menu-list-1,
            .burger-menu-list-2,
            .burger-menu-list-3,
            .burger-menu-login {
              padding-left: 100px;
              padding-bottom: $spacing-md;

              @include mq($mq-sm) {
                padding-left: $spacing-xl;
              }
            }

            .burger-menu-list-1 p {
              font-size: pxToRem(20px) !important;
            }
            .burger-menu-list-2 p {
              font-size: pxToRem(15px) !important;
            }
            .burger-menu-list-3 p {
              font-size: pxToRem(15px) !important;
              font-weight: 400 !important;
            }

            .burger-menu-item,
            .burger-menu-item-category {
              margin-bottom: $spacing-md;

              .burger-menu-item-link {
                margin-bottom: $spacing-md;
                position: relative;
                display: flex;
                align-items: center;
                gap: $spacing-sm;
                text-decoration: none;
                color: var(--dark-blue);
                cursor: pointer;
                transition: background-color var(--transition-duration) var(--transition-timing-function);
                padding-right: $spacing-xl;
                font-size: pxToRem(14px);

                &:hover,
                &.active {
                  text-decoration: underline;

                  .burger-menu-item-link-off {
                    display: none;
                  }

                  .burger-menu-item-link-on {
                    display: block;
                  }

                  .burger-menu-item-link-icon {
                    display: flex;
                  }
                }

                &-on {
                  display: none;
                }

                img {
                  height: 28px;
                  width: 28px;
                  flex: 0 0 28px;
                  border-radius: 5px;
                  object-fit: cover;
                }

                p {
                  font-size: pxToRem(14px);
                  font-weight: 800;
                }

                &-icon {
                  display: none;
                  position: absolute;
                  right: $spacing-md;
                }

                &.all-products {
                  font-weight: 800;
                  padding-bottom: $spacing-md;
                }

                &.active {
                  text-decoration: underline;
                }
              }

              &:hover {
                a {
                  background-color: rgba(var(--primary), 0.25);
                }
              }
            }

            .burger-menu-item-inspiration {
              display: flex;
              flex-wrap: wrap;
              gap: $spacing-md;
              padding-left: $spacing-md;

              &-item {
                cursor: pointer;

                &:hover {
                  p {
                    text-decoration: underline;
                  }
                }

                &-img {
                  width: 135px;
                  height: 90px;
                  border-radius: $spacing-xs;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  @include mq($mq-sm) {
                    width: 150px;
                    height: 90px;
                  }
                }

                &-name {
                  padding-top: $spacing-sm;
                  font-weight: 500;
                  font-size: pxToRem(12px) !important;
                }
              }
            }

            .burger-menu-item-category {
              margin-left: $spacing-xxxl;

              @include mq($mq-sm) {
                margin-left: $spacing-md;
              }

              p {
                font-weight: 400 !important;
              }

              &.subcategory {
                margin-left: $spacing-xl;

                .burger-menu-item-link:hover {
                  .burger-menu-item-link-icon {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.open {
      backdrop-filter: blur(2px);
      visibility: visible;

      .burger-menu-bg {
        opacity: 0.4;
      }

      .burger-menu-content {
        transform: translate(0, 0);
      }
    }
  }
}

.burger-menu-custom-scrollbar {
  // ::-webkit-scrollbar only for webkit browsers - not working on firefox
  &::-webkit-scrollbar {
    width: $spacing-xs;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--dark-blue);
    border-radius: $spacing-xs;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: var(--bg-grey);
    border-radius: $spacing-xs;
  }
}
</style>
