import { ref } from 'vue'
import { useStock } from './useStock'
import { useCartItem } from './useCartItem'
import { useReference } from './useReference'
import { useSnackbarStore } from '@/stores/snackbar'
import { useDeviceSize } from './useDeviceSize'
import { useAnalytics } from './useAnalytics'

export function useAddToCart() {
  const route = useRoute()
  const { isXs } = useDeviceSize()

  const stock = ref({})
  const fastAddToCart = ref(null)
  const quantity = ref(0)

  const { $api, $cart, $bus, $i18n } = useNuxtApp()
  const { analytics, events } = useAnalytics()
  const { request } = useApiRequest()
  const snackbarStore = useSnackbarStore()

  const addToCart = (accepted = false) => {
    if (fastAddToCart?.value) {
      // Query for references to have infos on the stock company to be able to add to cart.
      const stockOfferFilter = stock?.value?.reference?.bestStock?.company_slug
        ? {
            value: stock.value.reference.bestStock.company_slug,
            query: 'merchant_uid',
            type: 'MERCHANT_UID',
          }
        : {}
      Promise.allSettled([
        request({
          req: $api().reference.get(stock?.value?.reference?.slug),
        }),
        request({
          req: $api().reference.stockOffers(stock?.value?.reference?.slug, { page: 1, perPage: 1 }, stockOfferFilter),
        }),
      ]).then(async ([refResult, stockOffersResult]) => {
        // console.log(refResult.value, stockOffersResult.value.stock_offers)
        const ref = useReference(refResult.value || {}, stockOffersResult.value.stock_offers || [])
        const fullStock = await getFullCompanyInfos(ref)
        // console.log('fullStock', fullStock)
        let item
        try {
          item = useCartItem(fullStock, quantity?.value)
        } catch (error) {
          console.log(error)
          snackbarStore.showSnackbar({
            color: 'red',
            message: $i18n.t('backendError.cart_stock_not_created'),
            timeout: 2000,
            customTop: getSnackBarTop(),
          })
          return
        }
        if (fullStock?.reference?.accreditation && !accepted) {
          $bus.emit('modalOpen_confirmationModalAddToCart', 'confirmationModalAddToCart')
          return
        }
        $cart().addItem({
          ...item,
          stock: { ...item.stock, source_id: route.name },
        })
        $bus.emit('addToCart', { item, fastAddToCart: true })
        snackbarStore.showSnackbar({
          color: 'primary',
          message: $i18n.t('snackbar.productAddedToCart') + '<div class="icon-check u-bg-white" />',
          timeout: 2000,
          customTop: getSnackBarTop(),
        })
      })
    }

    $bus.emit('modalClose_confirmationModalAddToCart', 'confirmationModalAddToCart')
    if (stock?.value?.reference.accreditation && !accepted) {
      $bus.emit('modalOpen_confirmationModalAddToCart', 'confirmationModalAddToCart')
      return
    }
    const sourceId = route.name
    analytics.sendTagEvent(events.TAG_PLAN?.MKP?.CONFIRM_BUY)
    let ref = useReference(stock?.value?.o_reference || stock?.value?.reference || {})
    analytics.sendEvent(events.ADD_TO_CART, {
      probance: {
        item_id: ref.uid,
      },
      ecommerce: {
        currency: 'EUR',
        value: Number(stock?.value?.storePrice(true) * quantity?.value),
        tax: Number(ref.bestStock?.price_vat - ref.bestStock?.price) || 0,
        items: [
          {
            item_id: ref.trackingId,
            item_name: ref.name,
            name: ref.name,
            currency: 'EUR',
            discount: 0,
            item_brand: ref?.brand?.name,
            quantity: Number(quantity?.value),
            price: Number(stock?.value?.storePrice(true)),
            ...ref.categoriesName.reduce((a, b, i) => {
              return { ...a, [`item_category${i === 0 ? '' : i + 1}`]: b }
            }, {}),
            item_category4: 'product',
            ...(sourceId
              ? {
                  item_list_id: sourceId,
                  item_list_name: sourceId?.replaceAll('-', ' '),
                }
              : {}),
          },
        ],
      },
    })

    if (!fastAddToCart?.value) {
      const item = useCartItem(stock.value, quantity?.value)
      const { $cart } = useNuxtApp()
      $cart().addItem({
        ...item,
        stock: { ...item.stock, source_id: route.name },
      })
      $bus.emit('addToCart', { item, fastAddToCart: false })
    }
  }

  const getFullCompanyInfos = async reference => {
    // Get reference, order stocks like product page stocks() & selectedStock() (without route part) & get stock company
    if (reference?.availableStocks) {
      const stocks = reference.publicStocks?.map(child => {
        return useStock({ ...child, reference })
      })
      let _stock = null
      if (route.name.includes('merchant')) {
        _stock = stocks.find(s => s?.company?.slug === route.params.slug)
      }
      if (!_stock) {
        _stock = stocks?.sort((a, b) => {
          if (a.distance !== null && a.distance !== undefined) {
            return a.distance - b.distance
          }
          return a.price - b.price
        })[0]
      }
      return _stock
    }
    return null
  }

  const getSnackBarTop = () => {
    if (import.meta.client) {
      // Get the top position of snackbar according to header displaying
      const basicTop = 20
      const header = document.querySelector('.header-container')
      const mkpSubheading = document.querySelector('.mkp-subheading')
      const bottomHeaderTop = header.getBoundingClientRect().bottom
      const bottomMkpSubheadingTop = mkpSubheading?.getBoundingClientRect()?.bottom ?? 0
      const stickedMkpSubheading =
        bottomMkpSubheadingTop === mkpSubheading?.offsetHeight ||
        bottomMkpSubheadingTop === header.offsetHeight + mkpSubheading?.offsetHeight
      // Merchant
      const isMerchantProductPage = route.name === 'merchant-slug'
      const merchantHeader = document.querySelector('.merchant-header')
      const bottomMerchantHeaderTop = merchantHeader?.getBoundingClientRect()?.bottom
      const stickedMerchantMkpSubheading =
        bottomMkpSubheadingTop === mkpSubheading?.offsetHeight ||
        bottomMkpSubheadingTop === merchantHeader?.offsetHeight + mkpSubheading?.offsetHeight
      const stickedMerchantHeader = bottomMerchantHeaderTop === merchantHeader?.offsetHeight
      // Merchant Page
      if (isMerchantProductPage) {
        // If mkp-subheading is sticked, under it
        if (mkpSubheading && stickedMerchantMkpSubheading && !isXs) {
          return bottomMerchantHeaderTop + mkpSubheading.offsetHeight + basicTop
          // Else if merchant menu sticked, under itt
        } else if (stickedMerchantHeader && !isXs) {
          return merchantHeader.offsetHeight + basicTop
          // Else if header is visible, under it
        } else if (window.scrollY < bottomHeaderTop && !isXs) {
          return bottomHeaderTop + basicTop
        }
        // Else stick to top
        return basicTop
      } else if (!isMerchantProductPage) {
        // If mkp-subheading is sticked
        if (mkpSubheading && stickedMkpSubheading && !isXs) {
          return bottomHeaderTop + mkpSubheading.offsetHeight + basicTop
        }
        // Else only take header bottom from window top
        return bottomHeaderTop + basicTop
      }
      return basicTop
    }
  }
  return {
    addToCart,
    stock,
    fastAddToCart,
    quantity,
  }
}
